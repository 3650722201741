<!--
 * @Description: 公众号 缴费记录 selfPayRecord
 * @Author: zhoucheng
 * @Github: 无
 * @Date: 2021-04-01 01:02:46
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="缴费记录"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="contain">
      <van-row class="detailsBox"
               v-for="item in payRecordList"
               :key="item.carId"
               @click="toDetails(item)">
        <van-col :span="17"
                 class="details">
          <van-row class="lineTwo">
            <van-image class="datePic"
                       :src="require('@/assets/wxPayRecord/date.png')" />
            <span class="word">{{item.paymentTime}}</span>
          </van-row>
          <van-row class="lineThree">
            <van-image class="pricePic"
                       :src="require('@/assets/wxPayRecord/pay.png')" />
            <span class="word">{{item.paymentMoneyAmount}}元</span>
          </van-row>
        </van-col>
        <van-col :span="7"
                 class="payMethodsBox">
          <span class="payMethods">{{item.payMethod}}</span>
          <van-row class="arrowBox">
            <van-image class="arrow"
                       :src="require('@/assets/wxPayRecord/arrow.png')" />
          </van-row>
        </van-col>
      </van-row>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import getUserInfo from '@/utils/getUserInfo.js'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      payRecordList: []
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    getUserInfo().then(() => {
      this.getPayInfo()
    })
  },
  // 方法集合
  methods: {
    // 获取缴费记录
    getPayInfo () {
      this.$payRecord.getPaymentRecord().then(res => {
        res.resultEntity.forEach(item => {
          item.paymentMoneyAmount = (item.paymentMoneyAmount / 100).toFixed(2)
        })
        this.payRecordList = res.resultEntity
      })
    },
    // 跳转支付详情
    toDetails (item) {
      this.$router.push(
        {
          name: 'selfPayRecordInfo',
          query: {
            orderSequence: item.orderSequence
          }
        }
      )
    },
    // 点击返回事件
    handleClickTopBack () {
      this.$router.go(-1)
    }
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .contain {
    padding: 12px;
    .detailsBox {
      background: #fff;
      border-radius: 5px;
      margin-bottom: 12px;
      .details {
        height: 62px;
        padding: 12px;
        .lineTwo {
          height: 22px;
          line-height: 22px;
          .datePic {
            width: 13px;
            height: 11px;
            margin-right: 13px;
          }
        }
        .lineThree {
          height: 20px;
          line-height: 20px;
          .pricePic {
            width: 12px;
            height: 13px;
            margin-right: 13px;
          }
        }
        .word {
          font-size: 15px;
          color: #333333;
        }
      }
      .payMethodsBox {
        padding-top: 12px;
        .payMethods {
          display: inline-block;
          height: 20px;
          line-height: 20px;
          text-align: right;
          color: #999;
          font-size: 13px;
          width: 65px;
        }
        .arrowBox {
          display: inline-block;
          .arrow {
            width: 7px;
            height: 11px;
            margin-left: 11px;
          }
        }
      }
    }
  }
}
</style>
